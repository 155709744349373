var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"file-select mt-0 mb-3",class:{
      'default-round': !_vm.value && _vm.shape === 'round',
      'default-rectangle': !_vm.value && _vm.shape === 'rectangle',
      'default-hero': !_vm.value && _vm.shape === 'hero',
      'default-square': !_vm.value && !['round', 'rectangle', 'hero'].includes(_vm.shape),
      'mt-2': _vm.value && _vm.shape === 'rectangle',
      
    }},[(_vm.get_user_image())?_c('b-img',{class:{ round: _vm.shape === 'round' },attrs:{"src":_vm.bgImage,"fluid":"","alt":"Artist Banner Image"}}):_vm._e(),_c('input',{attrs:{"type":"file"},on:{"change":_vm.handleFileChange}})],1),_c('br')])}
var staticRenderFns = []

export { render, staticRenderFns }